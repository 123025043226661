import { ref } from "vue";
import { projectFirestore } from "../firebase/config";
import configSetup from "../setup";

const { companyProfileName } = configSetup();

const useDocument = (collection, id, subCollection) => {
  const error = ref(null);
  const isPending = ref(false);

  let galleryRef = projectFirestore
    .collection(collection)
    .doc(companyProfileName)
    .collection(subCollection)
    .doc(id);

  const deleteDoc = async () => {
    isPending.value = true;
    error.value = null;
    try {
      const res = await galleryRef.delete();
      isPending.value = false;

      return { res };
    } catch (err) {
      console.log(err.message);
      isPending.value = false;
      error.value = "Could not delete the document";
    }
  };

  const updateDoc = async (updates) => {
    isPending.value = true;
    error.value = null;
    try {
      const res = await galleryRef.update(updates);
      isPending.value = false;

      return { res };
    } catch (err) {
      console.log(err.message);
      isPending.value = false;
      error.value = "Could not update the document";
    }
  };

  let tintPriceRef = projectFirestore
    .collection(collection)
    .doc(companyProfileName)
    .collection(subCollection)
    .doc(id);

  const updateTintPrices = async (updates) => {
    isPending.value = true;
    error.value = null;
    try {
      const res = await tintPriceRef.update(updates);
      isPending.value = false;

      return { res };
    } catch (err) {
      console.log(err.message);
      isPending.value = false;
      error.value = "Could not update the document";
    }
  };

  const bookingRef = projectFirestore
    .collection(collection)
    .doc(companyProfileName)
    .collection(subCollection);

  const addBooking = async (bookingData) => {
    try {
      return await bookingRef.add(bookingData);
    } catch (e) {
      console.log(e);
    }
  };

  return {
    error,
    isPending,
    deleteDoc,
    updateDoc,
    updateTintPrices,
    addBooking,
  };
};

export default useDocument;
