import { ref, watchEffect } from "vue";
import { projectFirestore } from "../firebase/config";
import configSetup from "../setup";

const { companyProfileName } = configSetup();

const getCollection = () => {
  const documents = ref(null);
  const clients = ref(null);
  const error = ref(null);

  // register the firestore collection reference
  let collectionRef = projectFirestore
    .collection("companyProfile")
    .doc(companyProfileName)
    .collection("tintWarranties");

  const unsub = collectionRef.onSnapshot(
    (snap) => {
      let clientResults = [];
      snap.docs.forEach((doc) => {
        let docId = doc.id;
        let client = null;
        let warranty = null;
        warranty = doc.data();
        projectFirestore
          .collection("companyProfile")
          .doc(companyProfileName)
          .collection("tintWarranties")
          .doc(doc.id)
          .collection("client")
          .get()
          .then((querySnapshot) => {
            querySnapshot.docs.map((doc) => {
              client = doc.data();
              clientResults.push({
                ...doc.data(),
                id: docId,
                warranty: warranty,
              });
            });
          });
      });

      // update values
      clients.value = clientResults;

      error.value = null;
    },
    (err) => {
      console.log(err.message);
      documents.value = null;
      error.value = "could not fetch the data";
    }
  );

  watchEffect((onInvalidate) => {
    onInvalidate(() => unsub());
  });

  return { error, clients };
};

export default getCollection;
