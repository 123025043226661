import { ref, watchEffect } from "vue";
import { projectFirestore } from "../firebase/config";
import { projectAuth } from "../firebase/config";

const getOwner = (collection) => {
  const documents = ref(null);
  const clients = ref(null);
  const error = ref(null);
  const userId = projectAuth.currentUser.uid;
  // register the firestore collection reference
  let collectionRef = projectFirestore.collection(collection).doc(userId);

  collectionRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        const configId = doc.data().configId;
        let configRef = projectFirestore
          .collection(collection)
          .doc(userId)
          .collection("config")
          .doc(configId);
        configRef.get().then((doc) => {
          documents.value = doc.data();
        });
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    })
    .catch((error) => {
      console.log("Error getting document:", error);
    });

  return { error, clients, documents };
};

export default getOwner;
