import { ref } from "vue";
import { projectFirestore } from "../firebase/config";

const sendEmail = () => {
  const error = ref(null);

  const warrantyEmail = async (warrantyInfo, user) => {
    try {
      await projectFirestore
        .collection("warrantyEmail")
        .doc()
        .set(warrantyInfo, { uid: user }, { merge: true });
    } catch (err) {
      console.log(err);
    }
  };

  return { warrantyEmail };
};

export default sendEmail;
